<template>
  <div class="share-note">
    <h2 class="note-title">{{ noteTitle }}</h2>
    <div class="note-content" ref="noteContent">
      <MarkdownPreview v-if="noteContent" :initialValue="noteContent" @on-copy="onCopy" />
      <div v-else class="no-note-message">
        <p>笔记不存在，去创建</p>
        <router-link to="/noteList" class="create-note-link">点击这里创建新笔记</router-link>
      </div>
    </div>
    <div class="floating-buttons">
      <button @click="scrollToTop" class="floating-button">⬆️</button>
      <button @click="createNote" class="floating-button">✍️</button>
    </div>
  </div>
</template>

<script>
import { getShareNote } from "@/api/note";
import { MarkdownPreview } from "vue-meditor";

export default {
  data() {
    return {
      noteTitle: "",
      noteContent: "",
    };
  },
  components: {
    MarkdownPreview,
  },
  mounted() {
    const shareKey = this.$route.query.shareKey;
    if (shareKey) {
      this.fetchNoteData(shareKey);
    }
  },
  methods: {
    onCopy(val) {
      navigator.clipboard.writeText(val)
        .then(() => {
          this.$Message.success("复制成功");
        })
        .catch((err) => {
          this.$Message.error("复制失败");
          console.error("复制失败：", err);
        });
    },
    fetchNoteData(shareKey) {
      getShareNote({ shareKey: shareKey })
        .then(response => {
          if (response.code !== 0) {
            this.$Message.error("获取笔记失败:" + response.msg);
          } else {
            this.noteTitle = response.retVal.noteTitle;
            this.noteContent = response.retVal.noteContent;
          }
        })
        .catch(err => {
          this.$Message.error("获取笔记失败");
        });
    },
    scrollToTop() {
      this.$refs.noteContent.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动到内容顶部
    },
    createNote() {
      this.$router.push({ path: '/noteList' }); // 跳转到创建笔记页面
    },
  },
};
</script>

<style scoped>
.share-note {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.note-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  border-bottom: 2px solid #4CAF50;
  padding-bottom: 10px;
}

.note-content {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  min-height: 200px;
  max-height: 80vh;
  overflow-y: auto; /* 允许滚动 */
}

.no-note-message {
  text-align: center;
  color: #888;
  font-size: 20px;
  padding: 20px;
}

.create-note-link {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #4CAF50;
  color: #ffffff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.create-note-link:hover {
  background-color: #45a049;
}

.floating-buttons {
  position: fixed;
  bottom: 30px; /* 增加与底部的距离 */
  right: 30px; /* 增加与右侧的距离 */
  display: flex;
  flex-direction: column;
  gap: 10px; /* 按钮之间的间距 */
  z-index: 1000; /* 提高层级以确保可见性 */
}

.floating-button {
  width: 60px; /* 增加按钮大小 */
  height: 60px; /* 增加按钮大小 */
  border: none;
  border-radius: 50%;
  background-color: #4CAF50;
  color: white;
  font-size: 28px; /* 增加字体大小 */
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s;
}

.floating-button:hover {
  background-color: #45a049;
  transform: scale(1.05); /* 鼠标悬停时放大效果 */
}
</style>
